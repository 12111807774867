import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { FreeMode, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Container from '~/components/Container';
import SectionTitle from '~/components/SectionTitle';
import { styles as TextStyles } from '~/components/Text';
import { Identifiers } from '~/constants/enum';
import { QUERY_GET_CMS_BLOCKS } from '~/data/home';
import { replaceClassNames } from '~/utils/converters';
import styles from './TopCategory.module.scss';
import useClient from '~/hooks/useClient';
import { useRecoilState } from 'recoil';
import { cmsBlocksState } from '~/services/blocks';
import React from 'react';
import useCmsBlocks from '~/hooks/useCmsBlocks';

interface TopCategoryProps {
    title?: string;
}

const TopCategory: FC<TopCategoryProps> = ({ title = '' }) => {
    const isClient = useClient();
    const { cmsBlocks, loading } = useCmsBlocks([Identifiers.TopCategories]);
    const [items, setItems] = useState<Element[]>([]);

    const [childrenExist, setChildrenExist] = useState(false);

    const _title = cmsBlocks[Identifiers.TopCategories]?.title;
    const content = cmsBlocks[Identifiers.TopCategories]?.content;
    const cmsBlock = replaceClassNames(content, {
        TopCategory: styles,
        Text: TextStyles
    });

    const cmsBlockRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        if (cmsBlockRef.current) {
            cmsBlockRef.current.innerHTML = cmsBlock;
        }
        const items = Array.from(document.getElementsByClassName(styles.category_item));
        setItems(items);
        setChildrenExist(items.length > 0);
    }, [cmsBlock]);

    if (!isClient) return null;

    return (
        <React.Fragment>
            <Container rootClassName={styles.top_category_wrapper}>
                <SectionTitle centered text={_title} />
                {loading && (
                    <div className="flex justify-around">
                        {Array.from({ length: 6 }, (_, index: number) => (
                            <Skeleton
                                key={index}
                                height={180}
                                width={'100%'}
                                className="rounded-full aspect-square"
                            />
                        ))}
                    </div>
                )}

                {!!isClient && childrenExist && (
                    <Swiper
                        slidesPerView={3.5}
                        spaceBetween={10}
                        autoplay={{ delay: 3000 }}
                        freeMode
                        loop={items.length > 6}
                        modules={[FreeMode, Navigation, Pagination]}
                        navigation={{
                            enabled: false
                        }}
                        observer={true}
                        breakpoints={{
                            480: {
                                slidesPerView: 3.5
                            },
                            768: {
                                slidesPerView: 4.5
                            },
                            1024: {
                                slidesPerView: 6.5,
                                navigation: {
                                    enabled: true
                                }
                            }
                        }}
                    >
                        {items.map((child, index) => (
                            <SwiperSlide key={index}>
                                <div dangerouslySetInnerHTML={{ __html: child.outerHTML }} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}
                <div
                    ref={cmsBlockRef}
                    className="hidden"
                    dangerouslySetInnerHTML={{
                        __html: cmsBlock
                    }}
                />
            </Container>
        </React.Fragment>
    );
};

export default TopCategory;
